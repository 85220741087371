import React, { Fragment } from 'react'
import { Link, graphql } from 'gatsby'
import Carousel from 'nuka-carousel'
import Container from '../components/UI/Container'
import SEO from '../components/SEO'
import Img from 'gatsby-image'
import _ from 'underscore'
import styled from 'styled-components'
import Caret from '../images/caret.svg'

const CarouselWrapper = styled.section`
  border-color: ${props => props.theme.colors.red};
  border-style: solid;
  border-width: 0px 5px 5px 5px;
  background-color: ${props => props.theme.colors.grey};
  .imageWrapper {
    position: relative;
    height: 50vw;
  }
  .buttonLeft,
  .buttonRight {
    background: ${props => props.theme.colors.red};
    border: none;
    display: ${props => (props.singleImage ? `none` : `flex`)};
    cursor: pointer;
  }
  .buttonLeft {
    padding: 1rem 0.75rem 1rem 0.25rem;
    border-radius: 0 50px 50px 0;
  }
  .buttonRight {
    padding: 1rem 0.25rem 1rem 0.75rem;
    border-radius: 50px 0 0 50px;
  }
  .slider-control-bottomcenter {
    display: none;
  }
`
const VehicleImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 50vw;

  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`

const TitleWrapper = styled.div`
  background: ${props => props.theme.colors.grey};
  padding: 1rem 0;
  section {
    flex-direction: column;
    ol {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      li {
        margin-right: 2rem;
        position: relative;
        &:last-child {
          &:after {
            display: none;
          }
        }
        &:after {
          content: '';
          height: 14px;
          width: 14px;
          background: url(${Caret});
          background-size: cover;
          background-position: center;
          position: absolute;
          right: -22px;
          top: 4px;
        }
      }
      a {
        text-decoration-color: white;
      }
      span {
        color: white;
        text-transform: uppercase;
        letter-spacing: 3px;
      }
    }
    h1 {
      color: white;
      font-size: 40px;
      line-height: 46px;
      margin: 0;
      padding-top: 0.5rem;
      letter-spacing: 0.4rem;
    }
  }
`

const DetailWrapper = styled.div`
  margin-top: 2rem;
  section {
    margin: 3rem 0;
    .bio {
      box-shadow: 1px 2px 11px 0 rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      background: white;
      padding: 1.5rem 1rem;
      p {
        &:last-child {
          margin: 0;
        }
      }
    }
    .details {
      min-width: 200px;
      margin-left: 2rem;
      border-radius: 5px;
      .wrap {
        box-shadow: 1px 2px 11px 0 rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          border-radius: 5px;
          li {
            text-align: center;
            width: 100%;
            padding: 0.5rem;
            &:first-child {
              border-radius: 5px 5px 0 0;
            }
            &:last-child {
              border-radius: 0 0 5px 5px;
            }
            &:nth-child(2n - 1) {
              background: white;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.sizes.mobile}) {
    section {
      flex-wrap: wrap;
    }
    .details,
    .bio {
      width: 100%;
    }
    .details {
      margin-left: 0 !important;
      margin-top: 1.5rem;
      width: 100%;
    }
  }
`

export default class FleetVehicleTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideControls: false,
    }
  }
  _getImages = () => {
    let { edges } = this.props.data.vehicle
    let images = edges[0].node.data.vehicleImages.localFiles

    return _.map(images, (image, index) => {
      return (
        <div key={index} className="imageWrapper">
          <VehicleImg
            fluid={image.childImageSharp.fluid}
            backgroundColor={'#232324'}
          />
        </div>
      )
    })
  }

  _renderBreadcrumb = () => {
    let { edges } = this.props.data.vehicle
    let type = edges[0].node.data.vehicleType[0].data.name
    return (
      <ol itemScope itemType="http://schema.org/BreadcrumbList">
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link itemProp="item" to="/">
            <span itemProp="name">Home</span>
          </Link>
          <meta itemProp="position" content="1" />
        </li>
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link itemProp="item" to={`/fleet`}>
            <span itemProp="name">Fleet</span>
          </Link>
          <meta itemProp="position" content="2" />
        </li>
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link itemProp="item" to={`/fleet/${type.toLowerCase()}`}>
            <span itemProp="name">{type}</span>
          </Link>
          <meta itemProp="position" content="3" />
        </li>
      </ol>
    )
  }

  _hasOneImage = () => {
    let { edges } = this.props.data.vehicle
    let images = edges[0].node.data.vehicleImages.localFiles

    if (images.length === 1) {
      return true
    } else {
      return false
    }
  }

  render() {
    let { left, right } = this.props.data
    let {
      vehicleName,
      vehicleBio,
      vehicleMake,
      vehicleModel,
      vehicleYear,
    } = this.props.pageContext
    return (
      <Fragment>
        <SEO title={vehicleName} />
        <CarouselWrapper singleImage={this._hasOneImage()}>
          <Carousel
            autoplay={this._hasOneImage() ? false : true}
            wrapAround
            dragging={false}
            renderCenterLeftControls={({ previousSlide }) => (
              <button className={'buttonLeft'} onClick={previousSlide}>
                <Img fixed={left.childImageSharp.fixed} />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button className={'buttonRight'} onClick={nextSlide}>
                <Img fixed={right.childImageSharp.fixed} />
              </button>
            )}
          >
            {this._getImages()}
          </Carousel>
        </CarouselWrapper>
        <TitleWrapper>
          <Container>
            {this._renderBreadcrumb()}
            <h1>{vehicleName}</h1>
          </Container>
        </TitleWrapper>
        {vehicleBio && (
          <DetailWrapper>
            <Container>
              <div
                className="bio"
                dangerouslySetInnerHTML={{
                  __html: vehicleBio,
                }}
              />
              <div className="details">
                <div className="wrap">
                  <ul>
                    {vehicleMake && <li>{vehicleMake}</li>}
                    {vehicleYear && <li>{vehicleYear}</li>}
                    {vehicleModel && <li>{vehicleModel}</li>}
                  </ul>
                </div>
              </div>
            </Container>
          </DetailWrapper>
        )}
      </Fragment>
    )
  }
}

export const vehicleQuery = graphql`
  query($vehicleName: String!) {
    vehicle: allAirtable(
      filter: {
        table: { eq: "Vehicles" }
        data: { vehicleName: { eq: $vehicleName } }
      }
    ) {
      edges {
        node {
          data {
            vehicleBio
            vehicleMake
            vehicleModel
            vehicleYear
            vehicleImages {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 2200) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            vehicleType {
              data {
                name
              }
            }
          }
        }
      }
    }
    left: file(relativePath: { eq: "left.png" }) {
      childImageSharp {
        fixed(width: 23, height: 15) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fixed(width: 23, height: 15) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
